export default function ItemName({product, searchBox = false}){
    return(
        <div className={`flex flex-col w-full min-[395px]:text-base text-sm`}>
            {!searchBox && (
                <span className="whitespace-nowrap font-semibold leading-tight text-gray-800 group-hover:text-red-600 w-20">
                    {product.DOT_SKU ? (
                        <p className="whitespace-nowrap">
                            {product.pre_order == "1" && (
                            <span className="bg-yellow-400 text-black px-1 pb-px rounded mr-px tracking-wide">
                                Limited Stocks
                            </span>
                            )}
                            <span className="bg-indigo-600 text-white px-0.5 pb-px rounded mr-0.5 tracking-wide">
                            {product.DOT_SKU.replace(/[^\w\s]/gi, '')}
                            </span>
                        </p>
                    ): (
                        <>
                            {product.pre_order == "1" && (
                                <span className="bg-yellow-400 text-black px-1 pb-px rounded mr-px tracking-wide">
                                    Limited Stocks
                                </span>
                            )}
                        </>
                    )}
                </span>
            )}
            <span className={`${searchBox ? 'font-bold':'font-semibold'} leading-tight text-gray-800 group-hover:text-red-600 w-full`}>
                <span>
                    <span>{product.make}&nbsp;</span>
                    {product.section_width ? (
                        <span>{product.section_width}
                            <span className="text-red-600 group-hover:text-gray-800">/</span>
                        </span>
                        ) : ''}{product.aspect_ratio ? (
                        <span>{product.aspect_ratio}
                            <span className="text-red-600 group-hover:text-gray-800">/</span>
                        </span>
                    ): ''}
                    <span>{product.rim_size}&nbsp;</span>
                </span>
            </span>
            <span className={`${searchBox ? 'text-xs':'font-semibold'}  leading-tight text-gray-800 group-hover:text-red-600 w-full flex flex-wrap`}>
                <span>
                    <span>{product.pattern}&nbsp;</span>
                    <span>{product.load_rating}</span>
                    <span>{product.speed_rating}</span>
                </span>
            </span>
        </div>
    );
}
