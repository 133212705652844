import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, decrementQuantity, incrementQuantity, removeFromCart } from "@/redux/cart.slice";
import dayjs from "dayjs";
export const useCart = ({} = {}) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart);
    const tempCartItems = useSelector((state) => state.gulongph.temp_cart);

    const totalPromoDiscount = cartItems?.reduce(
        (accumulator, item) => {
            let total = 0;
            if(item.quantity >= 4 && item.buy3get1){
                const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
                total += parseFloat(itemPrice);
            }
            return accumulator + total;
        },
        0
    );

    const tempTotalPromoDiscount = tempCartItems?.reduce(
        (accumulator, item) => {
            let total = 0;
            if(item.quantity >= 4 && item.buy3get1){
                const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
                total += parseFloat(itemPrice);
            }
            return accumulator + total;
        },
        0
    );

    const totalBundlePromoDiscount = cartItems?.reduce(
        (accumulator, item) => {
            let total = 0;
            const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
            let isPromo = item.buy3get1 ? parseFloat(item.quantity) >= 4 ? true : false : false;
            if(item.DOT_SKU) isPromo = false;
            else isPromo = true;
            let totalDiscount = 0;
            if(isPromo && parseFloat(item.quantity) >= 4 && !item.buy3get1){
                totalDiscount = itemPrice - (parseFloat(item.quantity) >= 4) ? parseFloat(itemPrice) * 0.03 : 0;
            }
            total += item.quantity * totalDiscount;
            return accumulator + total;
        },
        0
    );

    const tempTotalBundlePromoDiscount = tempCartItems?.reduce(
        (accumulator, item) => {
            let total = 0;
            const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
            let isPromo = item.buy3get1 ? parseFloat(item.quantity) >= 4 ? true : false : false;
            if(item.DOT_SKU) isPromo = false;
            else isPromo = true;
            let totalDiscount = 0;
            if(isPromo && parseFloat(item.quantity) >= 4 && !item.buy3get1){
                totalDiscount = itemPrice - (parseFloat(item.quantity) >= 4) ? parseFloat(itemPrice) * 0.03 : 0;
            }
            total += item.quantity * totalDiscount;
            return accumulator + total;
        },
        0
    );

    const totalAmount = cartItems?.reduce(
        (accumulator, item) => {
            let total = 0;
            const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
            total += item.quantity * itemPrice;
            return accumulator + total;
        },
        0
    );

    const tempTotalAmount = tempCartItems?.reduce(
        (accumulator, item) => {
            let total = 0;
            const itemPrice = (item.sale_tag > 0) ? item.promo : item.srp;
            total += item.quantity * itemPrice;
            return accumulator + total;
        },
        0
    );

    const selectedBrands = cartItems?.map((item) => {return item.make;});

    const totalItem = cartItems.reduce((accumulator, itm) => accumulator + itm.quantity, 0);
    const tempTotalItem = tempCartItems?.reduce((accumulator, itm) => accumulator + itm.quantity, 0);
    const reservationFeeLimit = 40000;

    const addToCartGtag = (product, quantity = 1, promo = null) => {
        const chkPromo = promo ? 1 : 0;
        let currentDate = dayjs().format();
        dispatch(addToCart({...product, selected_quantity: quantity, buy_it_now: null, buy3get1: chkPromo, date_added_to_cart: currentDate }));
        window.dataLayer = window.dataLayer || [];
        //window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: 'PHP',
                value: product.sale_tag == 1 ? product.promo : product.srp,
                items: [{
                  item_id: product.id,
                  item_brand: product.make,
                  item_name: product.model,
                  item_category: product.tire_type,
                  index: 0,
                  price: product.sale_tag == 1 ? product.promo : product.srp,
                  discount: product.sale_tag == 1 ? product.srp - product.promo : 0,
                  quantity: chkPromo ? 4 : 1
                }]
            }
        });

        if(quantity >= 4){
            window.dataLayer = window.dataLayer || [];
            //window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
                event: 'promo',
                creative_name: "Buy 3 Get 1 free",
                creative_slot: "buy_3_1_free",
                location_id: product.slug,
                promotion_id: "buy3get1Promo",
                promotion_name: "Buy 3 tires and Get 1 free",
                ecommerce: {
                    items: [{
                        item_id: product.id,
                        item_name: product.model,
                        affiliation: "Gulong Web Store",
                        currency: "PHP",
                        price: product.product_price,
                        quantity: quantity,
                        item_brand: product.make,
                        item_category: product.tire_type,
                        index: 0
                    }]
                }
            });
            router.push('/checkout?step=appointment');
        }
    };

    const addToCartBuyNowGtag = (product, quantity = 1, promo = null) => {
        const chkPromo = promo ? 1 : 0;
        let currentDate = dayjs().format();
        dispatch(addToCart({...product, selected_quantity: quantity, buy_it_now: null, buy3get1: chkPromo, date_added_to_cart: currentDate }));
        window.dataLayer = window.dataLayer || [];
        //window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: 'PHP',
                value: product.sale_tag == 1 ? product.promo : product.srp,
                items: [{
                  item_id: product.id,
                  item_brand: product.make,
                  item_name: product.model,
                  item_category: product.tire_type,
                  index: 0,
                  price: product.sale_tag == 1 ? product.promo : product.srp,
                  discount: product.sale_tag == 1 ? product.srp - product.promo : 0,
                  quantity: chkPromo ? 4 : 1
                }]
            }
        });
    };

    const incrementQty = (product) =>{
        dispatch(incrementQuantity(product.id));
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'increase_quantity',
            ecommerce: {
                currency: 'PHP',
                value: product.sale_tag == 1 ? product.promo : product.srp,
                items: [{
                  item_id: product.id,
                  item_brand: product.make,
                  item_name: product.model,
                  item_category: product.tire_type,
                  index: 0,
                  price: product.sale_tag == 1 ? product.promo : product.srp,
                  discount: product.sale_tag == 1 ? product.srp - product.promo : 0,
                  quantity: 1
                }]
            }
        });
    };

    const decrementQty = (product) => {
        dispatch(decrementQuantity(product.id));
        window.dataLayer = window.dataLayer || [];
        //window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                items: [{
                    item_id: product.id,
                    item_name: product.model,
                    affiliation: "Gulong Web Store",
                    currency: "PHP",
                    price: product.product_price,
                    quantity: 1,
                    item_brand: product.make,
                    item_category: product.tire_type,
                    index: 0
                }]
            }
        });
    };

    const removeProduct = (product) => {
        dispatch(removeFromCart(product.id));
        window.dataLayer = window.dataLayer || [];
        //window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                currency: 'PHP',
                value: product.sale_tag == 1 ? product.promo : product.srp,
                items: [{
                  item_id: product.id,
                  item_brand: product.make,
                  item_name: product.model,
                  item_category: product.tire_type,
                  index: 0,
                  price: product.sale_tag == 1 ? product.promo : product.srp,
                  discount: product.sale_tag == 1 ? product.srp - product.promo : 0,
                  quantity: 1
                }]
                // items: [{
                //     item_id: product.id,
                //     item_name: product.model,
                //     affiliation: "Gulong Web Store",
                //     currency: "PHP",
                //     price: product.product_price,
                //     quantity: product.quantity,
                //     item_brand: product.make,
                //     item_category: product.tire_type,
                //     index: 0
                // }]
            }
        });
    };

    const viewProduct = (product, index = 0) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
                currency: 'PHP',
                value: product.sale_tag == 1 ? product.promo : product.srp,
                items: [{
                  item_id: product.id,
                  item_brand: product.make,
                  item_name: product.model,
                  item_category: product.tire_type,
                  index: 0,
                  price: product.sale_tag == 1 ? product.promo : product.srp,
                  discount: product.sale_tag == 1 ? product.srp - product.promo : 0,
                  quantity: 1
                }]
            },
            'eventCallback': function() {
                //router.push(`/product/${product.slug}`);
                //window.location.href = `/product/${product.slug}`;
            }
        });
        router.push(`/product/${product.slug}`);
        // window.dataLayer.push({
        //   event: "view_item",
        //   ecommerce: {
        //     items: [
        //       {
        //         item_id: product.id,
        //         item_name: product.model,
        //         affiliation: "Gulong Web Store",
        //         coupon: "",
        //         currency: "PHP",
        //         discount: 0,
        //         index: 0,
        //         item_brand: product.make,
        //         item_category: product.tire_type,
        //         location_id: product.slug,
        //         price: product.product_price,
        //         quantity: 1
        //       }
        //     ]
        //   },
        //   'eventCallback': function() {
        //       router.push(`/product/${product.slug}`);
        //   }
        // });
    };

    return {
        viewProduct,
        addToCartGtag,
        addToCartBuyNowGtag,
        incrementQty,
        decrementQty,
        removeProduct,
        totalAmount,
        tempTotalAmount,
        totalItem,
        selectedBrands,
        tempTotalItem,
        totalPromoDiscount,
        tempTotalPromoDiscount,
        tempTotalBundlePromoDiscount,
        totalBundlePromoDiscount,
        tempTotalBundlePromoDiscount,
        reservationFeeLimit
    }
}
