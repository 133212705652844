import Image from "next/image";
import { NumericFormat } from "react-number-format";
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import ButtonList from "../Buttons";
import ItemName from "../Name";
import { useCart } from "@/hooks/cart";

const ExtraImages = ({product, installmentBrandList}) =>{
    return(
        <Fragment>
            {(product?.promo_tag > 0) && (
                <div className={`absolute -top-[0.2rem] -right-[2px] h-6`}>
                    <Image
                        src="/assets/images/promo_sale_tag.webp"
                        alt="promo"
                        width={60}
                        height={60}
                    />
                </div>
            )}
            {(product?.clearance_sale > 0) && (
                <div className={`absolute -top-[0.2rem] -right-[2px] h-6`}>
                    <Image
                        src="/assets/images/clearance_sale.webp"
                        alt="promo"
                        width={60}
                        height={60}
                    />
                </div>
            )}
            {/*(todayBrand?.includes(product.make)) && (
            <div className={`z-10 absolute bottom-6 md:bottom-8 md:right-4 -right-2 md:w-20 sm:w-[60px] w-[80px] md:h-14 h-12`}>
                {product.product_banner && (
                    <Image
                        src="/assets/images/next-day-v3.webp"
                        alt="promo"
                        fill
                        sizes="(max-width: 480px) 100vw, (max-width: 800px) 50vw, 33vw"
                    />
                )}
            </div>
            )*/}
            {(product?.activity > 0) && (
                <div className={`absolute inset-y-1/3 -left-2 md:w-[70px] sm:w-[60px] w-[49px] sm:h-28 h-24`}>
                    {product?.product_banner && (
                        <Image
                            className="w-full"
                            src={product.product_banner}
                            alt="promo"
                            fill
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                        />
                    )}
                </div>
            )}
            {(installmentBrandList?.available_brands?.includes(product.make)) && (
                <div className={`absolute flex flex-col justify-end bottom-4 right-1 sm:h-[60px] sm:w-[100px] xs:h-[40px] xs:w-[75px] h-[35px] w-[70px]`}>
                    <Image
                        className="w-full"
                        src={'/assets/images/3m_badge.webp'}
                        alt="promo"
                        fill
                        quality={100}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default function Item({
    product,
    index,
    handleClickPop,
    brandsToday,
    paymentList = null,
    promobrands = null,
    locationDrawer,
    cCarDrawer,
    view = 'grid',
    handleCloseSearchBox,
    searchBox = false}){
    const router = useRouter();
    const btnCart = useRef();
    const [viewType, setViewType] = useState(0);
    const [promoButton, setPromoButton] = useState(false);
    const [todayBrand, setTodayBrand] = useState([]);
    const [installmentBrandList, setInstallmentBrandList] = useState(null);
    const { viewProduct } = useCart();

    useEffect(() =>{
        if(!router.isReady) return;
        if(router.query?.v) setViewType(router.query?.v ? router.query?.v : 0);
        else if(view == "list") setViewType(1);
        if(!promobrands) return;
        if(promobrands.includes(product.make) && !product.DOT_SKU){
            setPromoButton(true);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router, promobrands]);

    useEffect(() =>{
        if(!brandsToday) return;
        let tB = [];
        brandsToday?.map((brand, index) => {
            tB.push(brand.brand);
        });
        setTodayBrand(tB);
    },[brandsToday]);

    useEffect(() =>{
        if(!paymentList) return;
        setInstallmentBrandList(paymentList[0]);
    },[paymentList]);

    useEffect(() =>{
        btnCart.current = document.getElementsByClassName("btnCart")[0];
    },[]);
    return(
        <div className={`flex flex-col bg-white rounded border border-gray-100 shadow-lg hover:shadow-red-300 ${searchBox ? 'px-0 pb-0' : 'px-1 pb-1'} w-full h-full`}>
            <div className={`flex ${viewType == 0 ? 'flex-col' : searchBox ? 'flex-row ' : 'sm:flex-col flex-row'} justify-around h-full w-full mx-auto `}>
                <div
                    className={`relative flex-none w-full m-auto mt-0 ${searchBox ? 'flex justify-center align-middle items-center min-w-[50px] min-h-[50px]' : 'sm:min-w-[180px] sm:min-h-[180px] min-w-[160px] min-h-[160px]'} ${viewType == 0 ? '' : searchBox ? 'max-w-[86px] max-h-[86px]' : 'sm:max-w-full sm:max-h-full max-w-[90px] max-h-[90px]'} border-b border-gray-200 cursor-pointer hover:scale-95 transition-transform`}
                    onClick={() => {
                        if(typeof handleCloseSearchBox == "function") handleCloseSearchBox();
                        viewProduct(product, index);
                    }}
                >
                    <Image
                        className="object-fill"
                        src={product.image_do ? product.image_do : '/assets/images/sample_tire.png'}
                        alt={product.model}
                        width={800}
                        height={800}
                        priority
                    />
                    {!searchBox && (
                        <ExtraImages
                            product={product}
                            installmentBrandList={installmentBrandList}
                        />
                    )}
                </div>
                <div className={`${searchBox ? 'sm:pl-1 pl-0.5 flex-1' : 'flex-1'}`}>
                    <div
                        className={`cursor-pointer w-full ${searchBox ? '' : 'h-[76px]'} whitespace-pre-wrap group ${viewType == 0 ? searchBox ? 'pl-0.5':'sm:px-2 px-1' : searchBox ? 'pl-0.5':'sm:pl-0 pl-2'} flex flex-col align-middle items-center justify-around`}
                        onClick={() => {
                            if(typeof handleCloseSearchBox == "function") handleCloseSearchBox();
                            viewProduct(product, index);
                        }}
                    >
                        <ItemName searchBox={searchBox} product={product}/>
                    </div>
                    <div className={`w-full ${searchBox ? 'pl-0.5' : 'pl-2'} grid ${viewType == 0 ? 'grid-cols-3' : 'sm:grid-cols-3 grid-cols-1'}`}>
                        <div
                            onClick={() => {
                                if(typeof handleCloseSearchBox == "function") handleCloseSearchBox();
                                viewProduct(product, index);
                            }}
                            className={`cursor-pointer col-span-2 flex flex-col ${searchBox ? 'min-h-[40px] -mt-0.5' : 'min-h-[70px] -mt-1.5'} justify-center align-middle `}
                        >
                            <div className="flex flex-row align-middle items-center sm:space-x-2 space-x-1">
                                <NumericFormat className={`font-semibold sm:tracking-wider  whitespace-nowrap`} value={(product.sale_tag > 0) ? product.promo : product.srp} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                {(product.sale_tag > 0) && (
                                    <NumericFormat className={`line-through text-gray-400  ${searchBox ? 'text-xs' : 'sm:text-lg'} sm:tracking-wider whitespace-nowrap`} value={product.srp} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                )}
                            </div>
                            {(product.sale_tag > 0) && (
                                <div className="-mt-1">
                                    <span className={`${searchBox ? 'hidden text-xs' : 'text-sm'} whitespace-nowrap text-white rounded-lg bg-green-500 tracking-wide px-2 pb-px`}>
                                        Save&nbsp;
                                        <NumericFormat value={product.srp - product.promo} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                    </span>
                                </div>
                            )}
                            <span className={`${searchBox ? 'hidden text-xs' : 'text-sm'} text-gray-600 whitespace-nowrap`}>*Price per tire</span>
                            {promoButton && (
                                <div className={`${searchBox ? 'text-xs' : 'text-sm'} -mt-0.5`}>
                                    <p className="text-blue-600 font-semibold">Buy 3, Get 4th Free</p>
                                    <span className={`${searchBox ? 'hidden' : ''} whitespace-nowrap text-white rounded-lg bg-green-500 tracking-wide text-sm px-2 pb-px`}>
                                        Save&nbsp;
                                        <NumericFormat value={(product.sale_tag > 0) ? product.promo : product.srp} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                    </span>
                                </div>
                            )}
                            {(product.total_sold && parseFloat(product.total_sold) > 0) && (
                                <div className="flex flex-row align-middle items-center mt-px">
                                    <div className='w-[18px] h-[18px]'>
                                        <Image
                                            src="/assets/images/gulong_sold.webp"
                                            width={15}
                                            height={15}
                                            alt="total-sold"
                                        />
                                    </div>
                                    <span className="-mt-1 font-semibold text-sm text-[#CC6E00] flex flex-row align-middle items-center">{product.total_sold} sold</span>
                                </div>
                            )}
                        </div>
                        <div className={`${searchBox ? 'hidden' : 'flex'} ${viewType == 0 ? 'sm:flex-row flex-col' : 'lg:flex-col flex-row -mt-10'} flex-wrap align-bottom justify-end sm:pt-0 pt-2`}>
                            <div className="text-blue-800 hover:text-red-600 flex justify-end items-end hover:scale-105 transition-transform">
                                <a href={`https://m.me/gulongphilippines?ref=102452721335079&text=${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/product/${product.slug}`} rel="noopener noreferrer" target="_blank">
                                    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.36 2 2 6.13 2 11.7c0 2.91 1.19 5.44 3.14 7.17c.16.13.26.35.27.57l.05 1.78c.04.57.61.94 1.13.71l1.98-.87c.17-.06.36-.09.53-.06c.9.27 1.9.4 2.9.4c5.64 0 10-4.13 10-9.7C22 6.13 17.64 2 12 2m6 7.46l-2.93 4.67c-.47.73-1.47.92-2.17.37l-2.34-1.73a.6.6 0 0 0-.72 0l-3.16 2.4c-.42.33-.97-.17-.68-.63l2.93-4.67c.47-.73 1.47-.92 2.17-.4l2.34 1.76a.6.6 0 0 0 .72 0l3.16-2.4c.42-.33.97.17.68.63Z"/></svg>
                                </a>
                            </div>
                            <div className="text-blue-800 hover:text-red-600 flex justify-end items-end hover:scale-105 transition-transform">
                                <a target="_blank" rel="noopener noreferrer" href="tel:+639278691128" className="">
                                    <PhoneEnabledOutlinedIcon/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <ButtonList
                    className={`w-full flex-1 ${viewType == 0 ? 'flex' : searchBox ? 'flex sm:!w-28 !w-[84px] !flex-none justify-center' :'sm:flex hidden'}  flex-col justify-end gap-0.5 mt-1 xl:px-2 px-1`}
                    product={product}
                    btnCart={btnCart}
                    handleClickPop={handleClickPop}
                    promoButton={promoButton}
                    viewType={viewType}
                    todayBrand={todayBrand}
                    searchBox={searchBox}
                />
            </div>
            <ButtonList
                className={`w-full grid grid-cols-1 ${viewType == 1 ? searchBox ? 'hidden' : ' sm:hidden' : 'hidden'} gap-0.5 mt-1 xl:px-2 px-1`}
                product={product}
                btnCart={btnCart}
                handleClickPop={handleClickPop}
                promoButton={promoButton}
                viewType={viewType}
                todayBrand={todayBrand}
            />
            {typeof locationDrawer == "function" && (
                <div className="sm:px-2 px-1 my-2">
                    {(todayBrand?.includes(product.make)) ? (
                        <div
                            className={`${viewType == 1 ? 'text-sm' : 'xs:text-xs text-[0.7rem]'}  xs:tracking-normal tracking-tight flex flex-row align-middle items-center space-x-1 cursor-pointer`}
                            onClick={(e)=>{
                                locationDrawer(product, true);
                            }}
                        >
                            <div className="flex-none block relative xs:w-8 xs:h-7 w-6 h-5">
                                <Image
                                    src={`/assets/images/easy-installation.webp`}
                                    alt="easy-installation"
                                    fill
                                />
                            </div>
                            <div className="space-x-1">
                                <span className="text-green-600">Earliest Fitting date: Tomorrow</span>
                                <span>-</span>
                                <a
                                    className="text-blue-600 underline cursor-pointer whitespace-nowrap"
                                >
                                    See Installation locations
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${viewType == 1 ? 'text-sm' : 'xs:text-xs text-[0.7rem]'}  xs:tracking-normal tracking-tight flex flex-row align-middle items-center space-x-1 cursor-pointer`}
                            onClick={(e)=>{
                                locationDrawer(product);
                            }}
                        >
                            <div className="flex-none block relative xs:w-8 xs:h-7 w-6 h-5">
                                <Image
                                    src={`/assets/images/easy-installation.webp`}
                                    alt="easy-installation"
                                    fill
                                />
                            </div>
                            <div className="space-x-1">
                                <a className="text-blue-600 underline ">
                                    <span className="whitespace-nowrap">See Installation locations</span> <span className="whitespace-nowrap">and Earliest Fitting Date</span>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {typeof cCarDrawer == "function" && (
                <div className="sm:px-2 px-1 mb-1 pt-2 border-t border-gray-200">
                    <div
                        className={`${viewType == 1 ? 'text-sm' : 'xs:text-xs text-[0.7rem]'}  xs:tracking-normal tracking-tight flex flex-row align-middle items-center space-x-1 cursor-pointer`}
                        onClick={(e)=>{
                            cCarDrawer(product);
                        }}
                    >
                        <div className="flex-none flex justify-center items-center align-middle relative xs:w-8 xs:h-5 w-6 h-4">
                            <Image
                                src={`/assets/images/car-icon.webp`}
                                alt="car-icon"
                                fill
                            />
                        </div>
                        <div className="space-x-1">
                            <a className="text-blue-600 underline ">
                                <span className="whitespace-nowrap">See Compatible Car Models</span>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
