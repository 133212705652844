import { useRouter } from "next/router";
import SellIcon from '@mui/icons-material/Sell';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { NumericFormat } from "react-number-format";
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, InputAdornment, Radio, RadioGroup, Snackbar } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import ItemName from "../Name";
import { useEffect, useState } from "react";
import { useCart } from "@/hooks/cart";
import BuildIcon from '@mui/icons-material/Build';

const PromoDialog = ({openPromoDialog, setOpenPromoDialog, product, addToCartGtag, title }) => {
    return(
        <Dialog
            className="rounded-full"
            sx={{
                "& .MuiDialog-container .MuiPaper-root":{
                    borderRadius: "1rem",
                    marginLeft: 1,
                    marginRight: 1,
                },
            }}
            open={openPromoDialog}
            onClose={()=>setOpenPromoDialog(!openPromoDialog)}
        >
            <DialogTitle className="!text-center text-white font-semibold text-3xl bg-red-600 !py-3 round">
                {title}
                <IconButton
                    aria-label="close"
                    onClick={()=>setOpenPromoDialog(!openPromoDialog)}
                    className="text-white !border-2 !border-white !rounded-full !border-solid hover:text-black"
                    sx={{
                        position: 'absolute',
                        right: 7,
                        top: 7,
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <h1 className="text-lg text-center font-semibold text-gray-800 mt-2">
                        Buy 3 Tires and Get&nbsp;
                        <span className="text-blue-600">1 FREE Tire!</span>
                    </h1>
                    <h1 className="text-lg text-center font-semibold text-gray-800">Valid until&nbsp;
                        <span className="text-red-600">{dayjs().endOf('month').format('MMMM D, YYYY')}</span>
                    </h1>
                    <table className="table-auto sm:text-xl text-lg mt-2">
                        <tr>
                            <td className="py-1">
                                <ItemName product={product}/>
                            </td>
                            <td className="text-center text-blue-600 sm:w-36 w-20 font-semibold">x3</td>
                            <td className="font-semibold text-gray-900 whitespace-nowrap text-right">
                                <NumericFormat value={(product?.sale_tag > 0) ? product?.promo : product?.srp} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                            </td>
                        </tr>
                        <tr className="border-t border-gray-200">
                            <td className="py-1">
                                <ItemName product={product}/>
                            </td>
                            <td className="text-center text-blue-600 font-semibold">x1</td>
                            <td className="font-semibold text-gray-900 whitespace-nowrap text-center">
                                <span className="text-green-600">FREE</span>
                            </td>
                        </tr>
                        <tr className="border-y border-gray-200">
                            <td className="text-right font-semibold py-1 text-gray-900">
                                Total
                            </td>
                            <td className="text-center text-blue-600 font-semibold">x4</td>
                            <td className="font-semibold text-gray-900 whitespace-nowrap text-right">
                                <NumericFormat value={((product?.sale_tag > 0) ? product?.promo : product?.srp) * 3} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                            </td>
                        </tr>
                    </table>
                    <h1 className="text-right mt-1">
                        <span className='text-white rounded-lg bg-green-600 tracking-wide text-lg px-2 pb-px'>
                            Save&nbsp;
                            <NumericFormat value={(product?.sale_tag > 0) ? product?.promo : product?.srp} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                        </span>
                    </h1>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="!flex !flex-row !justify-center !align-middle !items-center">
                <button
                    type="button"
                    onClick={()=>{
                        addToCartGtag(product, 4, true);
                    }}
                    className="text-white px-4 py-2 font-semibold bg-red-600 border border-red-400 rounded hover:text-red-600 hover:bg-white transition-all"
                >
                    Buy 3 Tires Now!
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default function ButtonList({product, todayBrand, promoButton, btnCart, handleClickPop, viewType = 0, withQuantity = 1, searchBox = false, ...props}){
    const router = useRouter();
    const [openPromoDialog, setOpenPromoDialog] = useState(false);
    const [appt, setAppt] = useState(false);
    const {addToCartGtag, addToCartBuyNowGtag} = useCart();
    const {
        register,
        unregister,
        handleSubmit,
        watch,
        control,
        reset,
        resetField,
        trigger,
        setValue,
        setError,
        clearErrors,
        getValues,
        formState: {
            errors,
            isDirty,
            dirtyFields
        }
    } = useForm({
        mode: 'onChange'
    });
    const buyNowProduct = (val, product) =>{
        addToCartBuyNowGtag(product, product.DOT_SKU ? 1 : 4, promoButton);
        setAppt(false);
        const timer = setTimeout(() => {
        if (val == 1) {
            router.push('/checkout?step=appointment');
        }else if (val == 4) {
            router.push('/checkout?step=home');
        }
        else{
            router.push('/checkout?step=del');
        }
        }, 1000);
        return () => clearTimeout(timer);
        // addToCartGtag(product, product.DOT_SKU ? 1 : 4, promoButton);
        // router.push('/checkout?step=appointment');
    };
    return(
        <div {...props}>
            <PromoDialog
                title={"3+1 Promo!"}
                openPromoDialog={openPromoDialog}
                setOpenPromoDialog={setOpenPromoDialog}
                addToCartGtag={addToCartGtag}
                product={product}
            />
            {promoButton && (
                <button
                    onClick={() => {
                        setOpenPromoDialog(true);
                    }}
                    type="button"
                    className={`${searchBox ? 'hidden' : ''} flex flex-col font-semibold justify-center align-middle items-center uppercase text-sm sm:px-2 px-px py-0.5 text-white bg-red-600 border border-red-500 hover:text-white hover:bg-red-700 rounded-sm sm:tracking-wider tracking-normal hover:scale-105 transition duration-200 ease-linear`}>
                        <LoyaltyIcon fontSize="small"/>
                        <span className="sm:tracking-normal tracking-tighter">Buy 3 get 1 free</span>
                </button>
            )}
            <div className={`grid ${viewType == 1 ? searchBox ? 'grid-cols-1 items-center align-middle' :'grid-cols-2' : 'grid-cols-1'} gap-1 w-full`}>
                <button
                    onClick={() => {
                        setAppt(true);
                    }}
                    type="button"
                    className={`${searchBox ? 'hidden' : ''} text-sm flex flex-col justify-center align-middle items-center uppercase font-semibold sm:px-2 px-px py-0.5 text-white bg-indigo-800 border-none border-indigo-600 hover:text-white hover:bg-indigo-900 rounded-sm sm:tracking-wider tracking-normal hover:scale-105 transition duration-200 ease-linear`}>
                        {(product?.DOT_SKU || promoButton) && (
                            <SellIcon fontSize="small"/>
                        )}
                        <span className="">{promoButton ? viewType == 0 ? 'Buy Now' : 'Buy Now' : product?.DOT_SKU ? 'Buy Now' : (
                            <span>
                                <p className="whitespace-nowrap">Buy 4 Tires</p>
                                <p className="whitespace-nowrap">
                                    <NumericFormat value={((product?.sale_tag > 0) ? product?.promo : product?.srp) - ((product?.sale_tag > 0) ? product?.promo : product?.srp) * 0.03} displayType={'text'} thousandSeparator={true} prefix={'₱ '} decimalScale={2} />
                                    /tire
                                </p>
                            </span>
                        )}</span>
                </button>
                <button
                    onClick={() => {
                        handleClickPop();
                        btnCart.current?.click();
                        addToCartGtag(product, withQuantity, promoButton);
                    }}
                    type="button"
                    className={`${searchBox ? 'text-xs whitespace-nowrap xl:px-1 px-1 py-0.5 sm:tracking-tight tracking-tighter' : 'text-sm xl:px-2 px-px py-0.5'} flex flex-col justify-center align-middle items-center uppercase font-semibold  bg-red-100 text-red-600 border border-red-500 hover:text-white hover:bg-red-700 rounded-sm tracking-normal hover:scale-105 transition duration-200 ease-linear`}>
                        <ShoppingCartIcon fontSize="small"/>
                        <span className={`${searchBox ? 'text-xs': 'text-sm'}`}>Add To Cart</span>
                </button>
            </div>
            <Dialog
                className="fixed inset-0 z-[1201] overflow-y-auto"
                onClose={() => {setAppt(true);}}
                open={appt}
            >
                    <DialogTitle className="bg-red-600 text-white font-semibold !py-2">
                      Choose your preferred option
                      <IconButton
                          aria-label="close"
                          onClick={()=>setAppt(false)}
                          className="!text-white !border-2 !border-white !rounded-full !border-solid hover:text-white w-8 h-8"
                          sx={{
                              position: 'absolute',
                              right: 7,
                              top: 7,
                          }}
                          >
                          <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent className="!py-0 my-4">
                        <div className="flex flex-col py-1">
                            <div className={`transition-all duration-500 ease-in-out bg-find-this bg-no-repeat sm:bg-[length:422px_240px] bg-[length:302px_150px] bg-top`}></div>
                            <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={(e, val)=>{
                                    buyNowProduct(val, product);
                                }}
                              >
                                <FormControlLabel value={1} control={<Radio />}
                                label={
                                    <div className={`flex flex-col justify-center align-middle py-1 group`}>
                                        <span className={`w-full font-semibold sm:text-lg group-hover:text-red-600`}>Free Installation at Partner Site</span>
                                        <p className={`text-gray-600 sm:text-sm text-xs font-semibold group-hover:text-red-600`}>
                                            Install your tires for FREE at nearest Partner site.
                                        </p>
                                        {todayBrand?.includes(product?.make) && (
                                          <span className="block sm:inline-block relative text-green-600">
                                              &nbsp;&nbsp;(&nbsp;<BuildIcon className="text-green-600" fontSize="small"/>&nbsp;
                                              Next-Day Installation)
                                              <span className="flex absolute h-1.5 w-1.5 top-0 left-9 md:left-8">
                                                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                                  <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-red-600"></span>
                                              </span>
                                          </span>
                                        )}
                                    </div>
                                }
                                />
                                {/*<hr className='my-4'/>
                                <FormControlLabel value={4} control={<Radio />}
                                label={
                                    <div className={`flex flex-col justify-center align-middle py-1 group`}>
                                        <span className={`w-full font-semibold sm:text-lg group-hover:text-red-600`}>Home Installation (Gulong on the Go!)</span>
                                        <p className={`text-gray-600 sm:text-sm text-xs font-semibold group-hover:text-red-600`}>
                                            We will install your tires right at your home.
                                        </p>
                                    </div>
                                }
                                />*/}
                                <hr className='my-4'/>
                                <FormControlLabel value={2} control={<Radio />}
                                label={
                                    <div className={`flex flex-col justify-center align-middle py-1 group`}>
                                        <span className={`w-full font-semibold sm:text-lg group-hover:text-red-600`}>Free Delivery to your Doorstep</span>
                                        <p className={`text-gray-600 sm:text-sm text-xs font-semibold group-hover:text-red-600`}>
                                            We offer FREE Delivery for your tires.
                                        </p>
                                    </div>
                                }
                                />
                              </RadioGroup>
                            </FormControl>
                        </div>
                    </DialogContent>
            </Dialog>
        </div>
    );
}
